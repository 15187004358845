<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <h1 class="toolbar--text text-center">{{ message }}</h1>
        <h4 class="text-center">{{ $t('You\'ll no longer to receive email marking from the BlockChainMetrics.') }}</h4>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { get, commit, call } from 'vuex-pathify'

export default {
  name:'Unsubscribe',
  data() {
    return {
      message: '',
    }
  },
  async mounted() {
    this.unsubscribeUser()
  },
  methods: {
    ...call('account', ['UNSUBSCRIBE_USER']),
    async unsubscribeUser() {
      const payload = {
        email: this.$route.query.email,
        confirmCode: this.$route.query.code,
      }

      const response = await this.UNSUBSCRIBE_USER(payload)

      if (response && response.status === 200) {
        this.message = response.data.detail.msg
      }
    },
  },
}
</script>
